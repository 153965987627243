'use strict';

let View,
  singleton;
const  template = require('templates/migrateContentV2'),
  account = require('models/account'),
  MigrateContentV2 = require('models/migrateContentV2'),
  notificationEvent = require('NotificationEvent'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  Utils = require('utils/utils'),
  State = require('../State'),
  MigrationProgressView = require('./migrationProgressView');

View = Backbone.View.extend({

  events: {
    'click #button-back': 'previousPage',
    'click #button-back-to': 'previousView',
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.contents) {
      this.contents.off(null, null, this);
    }
  },

  previousPage: function previousPage() {
    window.history.back();
  },
  previousView: function previousView() {
    State.goToLastView();
  },
  bindButtons: function bindButtons() {
    this.$el.find('#button-apply-migrate-content').off().click($.proxy(function () {
      try {
        if ($('.ace_error').length != 0) {
          notificationEvent.notify('JSON is not valid', 'danger');
        } else {
          this.migrateContent(true);
        }
      } catch (error) {
        notificationEvent.notify(error.message, 'danger');
      }
    }, this));
  },

  _disableBtn: function _disableBtn() {
    $('#button-apply-migrate-content').prop('disabled', true);
  },

  _enableBtn: function _enableBtn() {
    $('#button-apply-migrate-content').prop('disabled', false);
  },

  migrateContent: function migrateContent(retry) {
    let migrationList;
    try {
      const migrationData = this.jsonEditor.get();
      migrationList = (_.isArray(migrationData)) ? migrationData : [migrationData];
    } catch (error) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> migration data cannot be empty</li>'));
      return;
    }
    try {
      this.validateMigrationList(migrationList);
    } catch (error) {
      return;
    }
    const self = this;
    Confirm.showMe('You are about to apply the migrations', $.proxy(() => {
      self._disableBtn();
      notificationEvent.notify('Migration process starting...', 'warning');
      
      // Create progress display
      this.showProgressDiv();
      
      setTimeout(() => {
        // Generate a unique migration identifier using timestamp
        const migrationId = 'migration_' + Date.now() + '_' + Math.random().toString(36).slice(2, 11);

        const contentMigration = new MigrateContentV2({
          migrationList: migrationList,
          migrationId: migrationId
        });

        // Listen for progress updates
        contentMigration.on('progress:update', (progress) => {
          this.updateProgressDisplay(progress);
        });

        contentMigration.on('progress:error', () => {
          notificationEvent.notify('Error tracking migration progress', 'danger');
        });

        contentMigration.save(contentMigration.toJSON(), {
          success: function (response) {
            if (response) {
              const responseData = response.toJSON();
              
              // Start progress tracking with the received jobId
              if (responseData.jobId) {
                contentMigration.set('migrationId', responseData.jobId);
                contentMigration.startProgressTracking();
                
                // Show initial status
                notificationEvent.notify('Migration job started. You can track the progress below.', 'success');
              } else {
                notificationEvent.notify('Invalid response from server', 'danger');
                self._enableBtn();
              }
            }
          },
          error: function (model, error) {
            errorHandler({
              error: error,
              retry: retry,
              success: function () {
                if (retry) {
                  self.migrateContent(false);
                }
              },
              fail: function () {
                self._enableBtn();
              },
            });
          },
        });
      }, 500);
    }, this));
  },

  validateMigrationList: function validateMigrationList(migrationList) {
    if (!Utils.isNotEmptyArray(migrationList)) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration data, it must be a non-empty array of migration objects or a non-empty migration object with mandatory "id", "contentId", "locales" and "migrations" properties (migrations must be a non-empty array)</li>'));
      throw new Error();
    }


    for (let  i = 0; i < migrationList.length; i++) {
      const migration = migrationList[i];
      this.checkMigrationProperties(migration);
    }
  },

  checkMigrationProperties: function checkMigrationProperties(migration) {
    if (!Utils.isNotEmptyObject(migration)) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration, it must be a non-empty object with mandatory "id", "contentId", "locales" and "migrations" (migrations must be a non-empty array)</li>'));
      throw new Error();
    }

    if (!migration.id || !migration.contentId || !migration.locales || !migration.migrations || !migration.migrations.length) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration, it must be a non-empty object with mandatory "id", "contentId", "locales" and "migrations" properties (migrations must be a non-empty array)</li>'));
      throw new Error();
    }
  },

  resetInfoDivs: function resetInfoDivs() {
    const $errorDiv = $('.cms-migrateContent-error-display');
    $errorDiv.addClass('hidden');
    const $errorItems = $('.cms-migrateContent-error-item');
    $errorItems.remove();

    const $resultDiv = $('.cms-migrateContent-result-display');
    $resultDiv.addClass('hidden');
    const $resultContent = $('.cms-migrateContent-result-content');
    $resultContent.empty();
  },

  showErrorDiv: function showErrorDiv($errorElement) {
    this.resetInfoDivs();
    const $errorDiv = $('.cms-migrateContent-error-display');
    $errorDiv.removeClass('hidden');
    const $errorList = $('.cms-migrateContent-error-list');
    $errorElement.appendTo($errorList);
  },

  showResultDiv: function showResultDiv(result) {
    this.resetInfoDivs();
    const $resultDiv = $('.cms-migrateContent-result-display');
    $resultDiv.removeClass('hidden');
    const $resultContent = $('.cms-migrateContent-result-content');
    const sortedKeys = Object.keys(result).sort();
    const failedLocales = [];
    for (let  i = 0; i < sortedKeys.length; i++) {
      const key = sortedKeys[i];
      const value = result[key];
      if (value.indexOf('FAIL') >= 0) {
        failedLocales.push(key);
      }
    }
    if (failedLocales.length === 0) {
      $('<h3 class="cms-migrateContent-result-message cms-migrateContent-result-success">All locales successfully migrated</h3>').appendTo($resultContent);
    } else {
      $('<h3 class="cms-migrateContent-result-message cms-migrateContent-result-fail">' + failedLocales.length + ' locales could not be migrated: ' + failedLocales.join(', ') + '</h3>').appendTo($resultContent);
    }
    $('<br>').appendTo($resultContent);
    for (let  i = 0; i < sortedKeys.length; i++) {
      const key = sortedKeys[i];
      const value = result[key];
      $('<h4 class="cms-migrateContent-result-locale">Locale ' + key + ':</h4>').appendTo($resultContent);
      $('<p class="cms-migrateContent-result-message">' + value + '</p>').appendTo($resultContent);
      $('<br>').appendTo($resultContent);
    }
  },

  loadLocaleList: function loadLocaleList(event, noRetry) {
    this._resetLocalesSelect();
    const self = this;
    const contentId = this._getContentSelectValue();
    if (contentId) {
      Spinner.show();
      this._disableBtn();
      notificationEvent.notify('Loading content\'s locale list... Please wait.', 'warning');
      this.locales = new Locales({
        contentId: contentId,
      });
      this.locales.fetch({
        success: function (model) {
          Spinner.hide();
          self._enableBtn();
          self.locales = model.toJSON();
          self._initializeLocalesSelect();
        },
        error: function (model, error) {
          errorHandler({
            error: error,
            retry: !noRetry,
            success: function () {
              if (!noRetry) {
                self.loadLocaleList(event, true);
              }
            },
            fail: function () {
            },
          });
        },
      });
    }
  },

  render: function render() {
    Spinner.hide();
    if (!account.isDev()) {
      const appRouter = require('router');
      appRouter.navigate('welcome', { trigger: true });
      return;
    }
    const html = template({});
    this.$el.html(html);

    // Inject shared styles
    MigrationProgressView.injectStyles();

    $('#main').append(this.$el);
    this.bindButtons();
    const jsonContainer = document.getElementById('jsoneditor');
    const options = {
      mode: 'code',
      modes: ['code', 'tree'],
      error: function (err) {
        notificationEvent.notify(err.toString(), 'danger');
      },
    };
    this.jsonEditor = new JSONEditor(jsonContainer, options);
    this.jsonEditor.set([{
      id: '',
      contentId: '',
      locales: ['all'],
      migrations: [],
    }]);
    this.delegateEvents();
    return this;
  },

  showProgressDiv: function() {
    this.resetInfoDivs();
    const $resultDiv = $('.cms-migrateContent-result-display');
    $resultDiv.removeClass('hidden');
    const $resultContent = $('.cms-migrateContent-result-content');
    
    // Add initial progress container
    $resultContent.html(`
      <div class="cms-migrateContent-progress" style="padding: 10px;">
        <h3>Migration Progress: <span class="progress-numbers">0/0 (0%)</span></h3>
        <div class="time-estimate"></div>
        <div class="progress">
          <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>
        <div class="cms-migrateContent-progress-details"></div>
      </div>
    `);
  },

  formatTime: function(seconds) {
    if (!seconds) {return 'Calculating...';}
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const parts = [];
    if (hours > 0) {parts.push(`${hours}h`);}
    if (minutes > 0) {parts.push(`${minutes}m`);}
    if (remainingSeconds > 0 || parts.length === 0) {parts.push(`${remainingSeconds}s`);}

    return parts.join(' ');
  },

  updateProgressDisplay: function(progress) {
    if (!progress || typeof progress !== 'object') {
      console.warn('Invalid progress data received:', progress);
      return;
    }

    // Handle job completion status
    if (progress.status === 'completed' || progress.status === 'failed') {
      this._enableBtn();
      
      if (progress.status === 'completed') {
        notificationEvent.notify('Migration process finished successfully!', 'success');
      } else {
        notificationEvent.notify('Migration process failed. Check the details below.', 'danger');
      }
      
      // If we have a final result, show it
      if (progress.result) {
        this.showResultDiv(progress.result);
        return;
      }
    }

    const $resultContent = $('.cms-migrateContent-result-content');
    $resultContent.html(MigrationProgressView.generateProgressHtml(progress));
  },
}, {
  showMe: function showMe() {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    singleton.render();
    return singleton;
  },
});

module.exports = View;
