const icons = {
  // Actions
  ArrowDefaultLeft: 'ArrowDefaultLeft.svg',
  ArrowDefaultRight: 'ArrowDefaultRight.svg',
  ArrowDefaultUp: 'ArrowDefaultUp.svg',
  ArrowDefaultDown: 'ArrowDefaultDown.svg',
  ArrowFilledLeft: 'ArrowFilledLeft.svg',
  ArrowFilledRight: 'ArrowFilledRight.svg',
  ArrowFilledUp: 'ArrowFilledUp.svg',
  ArrowFilledDown: 'ArrowFilledDown.svg',
  ArrowOutlinedLeft: 'ArrowOutlinedLeft.svg',
  ArrowOutlinedRight: 'ArrowOutlinedRight.svg',
  ArrowOutlinedUp: 'ArrowOutlinedUp.svg',
  ArrowOutlinedDown: 'ArrowOutlinedDown.svg',
  ArrowTailLeft: 'ArrowTailLeft.svg',
  ArrowTailRight: 'ArrowTailRight.svg',
  ArrowTailUp: 'ArrowTailUp.svg',
  ArrowTailDown: 'ArrowTailDown.svg',
  Expand: 'Expand.svg',
  Reduce: 'Reduce.svg',
  StarFilled: 'StarFilled.svg',
  StarOutlined: 'StarOutlined.svg',
  HeartFilled: 'HeartFilled.svg',
  HeartOutlined: 'HeartOutlined.svg',
  CheckDefault: 'CheckDefault.svg',
  CheckFilled: 'CheckFilled.svg',
  CheckOutlined: 'CheckOutlined.svg',
  PlusDefault: 'PlusDefault.svg',
  PlusFilled: 'PlusFilled.svg',
  PlusOutlined: 'PlusOutlined.svg',
  MinusDefault: 'MinusDefault.svg',
  MinusFilled: 'MinusFilled.svg',
  MinusOutlined: 'MinusOutlined.svg',
  CrossDefault: 'CrossDefault.svg',
  CrossFilled: 'CrossFilled.svg',
  CrossOutlined: 'CrossOutlined.svg',
  Delete: 'Delete.svg',
  Reload: 'Reload.svg',
  Share: 'Share.svg',
  Download: 'Download.svg',
  Compare: 'Compare.svg',
  Edit: 'Edit.svg',
  Search: 'Search.svg',
  Information: 'Information.svg',
  Error: 'Error.svg',
  New: 'New.svg',
  // Utilities
  Clipboard: 'Clipboard.svg',
  CalendarDefault: 'CalendarDefault.svg',
  CalendarFavorites: 'CalendarFavorites.svg',
  CalendarOptions: 'CalendarOptions.svg',
  CalendarPlus: 'CalendarPlus.svg',
  DocumentChecklist: 'DocumentChecklist.svg',
  DocumentDefault: 'DocumentDefault.svg',
  DocumentInsurrance: 'DocumentInsurrance.svg',
  DocumentList: 'DocumentList.svg',
  DocumentPDF: 'DocumentPDF.svg',
  DocumentPassport: 'DocumentPassport.svg',
  DocumentTicket: 'DocumentTicket.svg',
  DocumentUserDetails: 'DocumentUserDetails.svg',
  CallBack: 'CallBack.svg',
  ClockBack: 'ClockBack.svg',
  ClockDefault: 'ClockDefault.svg',
  ClockSpeed: 'ClockSpeed.svg',
  '360Videos': '360Videos.svg',
  Pictures: 'Pictures.svg',
  Videos: 'Videos.svg',
  CreditCard: 'CreditCard.svg',
  Filters: 'Filters.svg',
  Folder: 'Folder.svg',
  GridDefault: 'GridDefault.svg',
  GridList: 'GridList.svg',
  HappyFirst: 'HappyFirst.svg',
  Home: 'Home.svg',
  Internet: 'Internet.svg',
  Invisible: 'Invisible.svg',
  LastMinute: 'LastMinute.svg',
  Letter: 'Letter.svg',
  Menu: 'Menu.svg',
  MenuClosed: 'MenuClosed.svg',
  Padlock: 'Padlock.svg',
  PaymentPlan: 'PaymentPlan.svg',
  PeopleBaby: 'PeopleBaby.svg',
  PeopleDouble: 'PeopleDouble.svg',
  PeopleFamily: 'PeopleFamily.svg',
  PeopleKids: 'PeopleKids.svg',
  PeopleSingle: 'PeopleSingle.svg',
  PeopleTeenagers: 'PeopleTeenagers.svg',
  Phone: 'Phone.svg',
  PromoCode: 'PromoCode.svg',
  Questions: 'Questions.svg',
  SMS: 'SMS.svg',
  Smartphone: 'Smartphone.svg',
  SpecialOffers: 'SpecialOffers.svg',
  Visible: 'Visible.svg',
  VoiceMail: 'VoiceMail.svg',
  // Transports
  AgencyLocation: 'AgencyLocation.svg',
  Boat: 'Boat.svg',
  Bus: 'Bus.svg',
  CableCar: 'CableCar.svg',
  Car: 'Car.svg',
  GlobePlane: 'GlobePlane.svg',
  GlobeTrident: 'GlobeTrident.svg',
  Location: 'Location.svg',
  Parking: 'Parking.svg',
  PlaneDefault: 'PlaneDefault.svg',
  PlaneInBound: 'PlaneInBound.svg',
  PlaneNoPlane: 'PlaneNoPlane.svg',
  PlaneOutbound: 'PlaneOutbound.svg',
  Train: 'Train.svg',
  // Socials
  Facebook: 'Facebook.svg',
  FacebookMessenger: 'FacebookMessenger.svg',
  Flickr: 'Flickr.svg',
  Google: 'Google.svg',
  Instagram: 'Instagram.svg',
  Pinterest: 'Pinterest.svg',
  TripAdvisor: 'TripAdvisor.svg',
  Twitter: 'Twitter.svg',
  VK: 'VK.svg',
  Vine: 'Vine.svg',
  WeChat: 'WeChat.svg',
  WeChatPay: 'WeChatPay.svg',
  Weibo: 'Weibo.svg',
  WhatsApp: 'WhatsApp.svg',
  YouTube: 'YouTube.svg',
  // Services
  AllInclusive: 'AllInclusive.svg',
  BabyBottle: 'BabyBottle.svg',
  BabyWelcome: 'BabyWelcome.svg',
  ClothesDryer: 'ClothesDryer.svg',
  Concierge: 'Concierge.svg',
  FourtyFive: 'FourtyFive.svg',
  Hairdresser: 'Hairdresser.svg',
  KidSupervisory: 'KidSupervisory.svg',
  Laundry: 'Laundry.svg',
  LuggageTransportation: 'LuggageTransportation.svg',
  Maintenance: 'Maintenance.svg',
  Massage: 'Massage.svg',
  Photographer: 'Photographer.svg',
  Pressing: 'Pressing.svg',
  Shopping: 'Shopping.svg',
  SkiInAndOut: 'SkiInAndOut.svg',
  Spa: 'Spa.svg',
  Sterilizer: 'Sterilizer.svg',
  Stroller: 'Stroller.svg',
  VideoSurveillance: 'VideoSurveillance.svg',
  WelcomeGift: 'WelcomeGift.svg',
  // Room
  AC: 'AC.svg',
  Amenities: 'Amenities.svg',
  BabyBed: 'BabyBed.svg',
  Balcony: 'Balcony.svg',
  Balneotherapy: 'Balneotherapy.svg',
  Bathrobe: 'Bathrobe.svg',
  BathtubShower: 'BathtubShower.svg',
  BeachTowel: 'BeachTowel.svg',
  Bedroom: 'Bedroom.svg',
  Bidet: 'Bidet.svg',
  ChildrenSeparatedRoom: 'ChildrenSeparatedRoom.svg',
  Daybed: 'DayBed.svg',
  Dressing: 'Dressing.svg',
  Dryer: 'Dryer.svg',
  DualBasin: 'DualBasin.svg',
  Electricity: 'Electricity.svg',
  Fireplace: 'Fireplace.svg',
  Fridge: 'Fridge.svg',
  Household: 'Household.svg',
  LoungeAreaInside: 'LoungeAreaInside.svg',
  LoungeAreaOutside: 'LoungeAreaOutside.svg',
  MagnifyingMirror: 'MagnifyingMirror.svg',
  Multimedia: 'Multimedia.svg',
  Ofuro: 'Ofuro.svg',
  RoomSafe: 'RoomSafe.svg',
  RoomService: 'RoomService.svg',
  SeparatedRoom: 'SeparatedRoom.svg',
  SeparatedWC: 'SeparatedWC.svg',
  Shower: 'Shower.svg',
  ShowerPMR: 'ShowerPMR.svg',
  ShowerWC: 'ShowerWC.svg',
  Size: 'Size.svg',
  TV: 'TV.svg',
  WIFI: 'WIFI.svg',
  // Places
  BabyCleaning: 'BabyCleaning.svg',
  BabyCorner: 'BabyCorner.svg',
  Conference: 'Conference.svg',
  Elevator: 'Elevator.svg',
  Footpath: 'Footpath.svg',
  InclusiveToilets: 'InclusiveToilets.svg',
  Library: 'Library.svg',
  Luggage: 'Luggage.svg',
  MeetingPoint: 'MeetingPoint.svg',
  Nursery: 'Nursery.svg',
  PMR: 'PMR.svg',
  PMRElevator: 'PMRElevator.svg',
  Reception: 'Reception.svg',
  SkiRoom: 'SkiRoom.svg',
  SwimmingArea: 'SwimmingArea.svg',
  Theater: 'Theater.svg',
  ZenPool: 'ZenPool.svg',
  // Happy To Care
  ActionsAgainstSexualTourism: 'ActionsAgainstSexualTourism.svg',
  AnimalProtection: 'AnimalProtection.svg',
  Biodiversity: 'Biodiversity.svg',
  ByeByePlastic: 'ByeByePlastic.svg',
  ClimateActions: 'ClimateActions.svg',
  CulturalRespect: 'CulturalRespect.svg',
  ElectricityManagement: 'ElectricityManagement.svg',
  EnvironmentProtection: 'EnvironmentProtection.svg',
  EqualOpportunities: 'EqualOpportunities.svg',
  FarmersProgram: 'FarmersProgram.svg',
  HealthyCorner: 'HealthyCorner.svg',
  LocalChildrenWelcome: 'LocalChildrenWelcome.svg',
  LocalDevelopment: 'LocalDevelopment.svg',
  LocalSportSchool: 'LocalSportSchool.svg',
  MaterialDonations: 'MaterialDonations.svg',
  WellBeingAtWork: 'WellBeingAtWork.svg',
  // Food
  Bar: 'Bar.svg',
  Champagne: 'Champagne.svg',
  Charcuterie: 'Charcuterie.svg',
  Cheese: 'Cheese.svg',
  CoffeeMachine: 'CoffeeMachine.svg',
  ColdDrinks: 'ColdDrinks.svg',
  ColdSandwich: 'ColdSandwich.svg',
  Dessert: 'Dessert.svg',
  GourmetLounge: 'GourmetLounge.svg',
  Hamburger: 'Hamburger.svg',
  HotDrinks: 'HotDrinks.svg',
  HotSandwiches: 'HotSandwiches.svg',
  Kitchen: 'Kitchen.svg',
  KitchenEquipments: 'KitchenEquipments.svg',
  Kitchenware: 'Kitchenware.svg',
  Meal: 'Meal.svg',
  Meat: 'Meat.svg',
  PrivateMeal: 'PrivateMeal.svg',
  Quiche: 'Quiche.svg',
  Restaurant: 'Restaurant.svg',
  Salad: 'Salad.svg',
  Snacks: 'Snacks.svg',
  Soup: 'Soup.svg',
  TakeAway: 'TakeAway.svg',
  Tapas: 'Tapas.svg',
  TeaMaker: 'TeaMaker.svg',
  Vegan: 'Vegan.svg',
  Vegetables: 'Vegetables.svg',
  Vegetarian: 'Vegetarian.svg',
  WaterFountain: 'WaterFountain.svg',
  Wine: 'Wine.svg',
  WineCellar: 'WineCellar.svg',
  // Covid
  Capacity: 'Capacity.svg',
  Cough: 'Cough.svg',
  DigitalTools: 'DigitalTools.svg',
  Disinfection: 'Disinfection.svg',
  Distance: 'Distance.svg',
  DoNotShakeHands: 'DoNotShakeHands.svg',
  Doctor: 'Doctor.svg',
  Gloves: 'Gloves.svg',
  Mask: 'Mask.svg',
  PCRTest: 'PCRTest.svg',
  SafeReception: 'SafeReception.svg',
  SafeRestaurant: 'SafeRestaurant.svg',
  SafeSpa: 'SafeSpa.svg',
  Soap: 'Soap.svg',
  Solution: 'Solution.svg',
  TemperatureCheck: 'TemperatureCheck.svg',
  WashHands: 'WashHands.svg',
  // Brand
  AllInclusiveDefault: 'AllInclusiveDefault.svg',
  AllInclusiveDonations: 'AllInclusiveDonations.svg',
  AllInclusiveFlexible: 'AllInclusiveFlexible.svg',
  BabyClubMed: 'BabyClubMed.svg',
  Bars: 'Bars.svg',
  ChillPass: 'ChillPass.svg',
  ClubMed: 'ClubMed.svg',
  Diamond: 'Diamond.svg',
  ExclusiveCollection: 'ExclusiveCollection.svg',
  HappyToCare: 'HappyToCare.svg',
  HappyToCareSmall: 'HappyToCareSmall.svg',
  MiniClubMed: 'MiniClubMed.svg',
  OnDemand: 'OnDemand.svg',
  PetitClubMed: 'PetitClubMed.svg',
  Restaurants: 'Restaurants.svg',
  Teens: 'Teens.svg',
  Trident: 'Trident.svg',
  // Activities
  AmazingFamily: 'AmazingFamily.svg',
  Archery: 'Archery.svg',
  Badminton: 'Badminton.svg',
  BocceBall: 'BocceBall.svg',
  Cardio: 'Cardio.svg',
  Circus: 'Circus.svg',
  Climbing: 'Climbing.svg',
  Danse: 'Danse.svg',
  Fitness: 'Fitness.svg',
  Golf: 'Golf.svg',
  HamamMen: 'HamamMen.svg',
  HamamWomen: 'HamamWomen.svg',
  Hiking: 'Hiking.svg',
  HorseRiding: 'HorseRiding.svg',
  IceSkating: 'IceSkating.svg',
  Kayak: 'Kayak.svg',
  Kitesurf: 'Kitesurf.svg',
  Multisports: 'Multisports.svg',
  Paddle: 'Paddle.svg',
  PaddleTennis: 'PaddleTennis.svg',
  PickleBall: 'PickleBall.svg',
  Running: 'Running.svg',
  Sailing: 'Sailing.svg',
  SaunaMen: 'SaunaMen.svg',
  SaunaWomen: 'SaunaWomen.svg',
  ScubaDiving: 'ScubaDiving.svg',
  Ski: 'Ski.svg',
  Snorkeling: 'Snorkeling.svg',
  Snowboard: 'Snowboard.svg',
  SnowSerenity: 'SnowSerenity.svg',
  Snowshoeing: 'Snowshoeing.svg',
  Surf: 'Surf.svg',
  TableTennis: 'TableTennis.svg',
  Tennis: 'Tennis.svg',
  ThaiBoxing: 'ThaiBoxing.svg',
  Trapeze: 'Trapeze.svg',
  TreeClimbing: 'TreeClimbing.svg',
  Volleyball: 'Volleyball.svg',
  VTT: 'VTT.svg',
  WaterSkiing: 'WaterSkiing.svg',
  Waterpolo: 'Waterpolo.svg',
  WaterVolley: 'WaterVolley.svg',
  Windsurf: 'Windsurf.svg',
  Wingfoil: 'Wingfoil.svg',
  WinterHiking: 'WinterHiking.svg',
  Yoga: 'Yoga.svg',
};

module.exports = { dcxIcons: icons };