'use strict';

const MigrationProgressView = {
  finishedStates: ['completed', 'error'],

  formatTime: function(seconds) {
    if (!seconds) { return 'Calculating...'; }
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const parts = [];
    if (hours > 0) { parts.push(`${hours}h`); }
    if (minutes > 0) { parts.push(`${minutes}m`); }
    if (remainingSeconds > 0 || parts.length === 0) { parts.push(`${remainingSeconds}s`); }

    return parts.join(' ');
  },

  calculateProgress: function(completed, total) {
    if (!total) { return 0; }
    return parseFloat((completed / total) * 100).toFixed(2);
  },

  generateProgressHtml: function(progress) {
    if (!progress || !progress.details) {
      return '';
    }

    const percentage = this.calculateProgress(progress.completed, progress.total);
    
    let html = `
      <div class="cms-migrateContent-progress" style="padding: 10px;">
        <h3>Migration Progress: <span class="progress-numbers">${progress.completed || 0}/${progress.total || 0} (${percentage}%)</span></h3>
    `;

    // Add time estimate section
    if (progress.timeEstimate) {
      html += `
        <div class="time-estimate">
          <div class="time-info">
            <span class="time-label">Time Remaining:</span> 
            <span class="time-value">${!this.finishedStates.includes(progress.status) ? this.formatTime(progress.timeEstimate.estimatedTimeLeft) : 'Done'}</span>
          </div>
          <div class="time-info">
            <span class="time-label">Elapsed Time:</span> 
            <span class="time-value">${this.formatTime(progress.timeEstimate.elapsedTime)}</span>
          </div>
        </div>
      `;
    }

    // Add overall progress bar
    html += `
      <div class="progress mb-3">
        <div class="progress-bar" role="progressbar" 
             style="width: ${percentage}%;" 
             aria-valuenow="${percentage}" 
             aria-valuemin="0" 
             aria-valuemax="100">
          ${percentage}%
        </div>
      </div>
    `;

    // Add content-level progress
    html += '<div class="cms-migrateContent-progress-details">';
    Object.entries(progress.details).forEach(([contentId, contentProgress]) => {
      if (!contentProgress || typeof contentProgress !== 'object') { return; }

      const contentPercentage = this.calculateProgress(contentProgress.completed, contentProgress.total);

      html += `
        <div class="content-progress">
          <h4>Content: ${contentId} (${contentProgress.status || 'unknown'}) - ${contentProgress.completed || 0}/${contentProgress.total || 0}</h4>
          <div class="progress mb-3">
            <div class="progress-bar" role="progressbar" 
                 style="width: ${contentPercentage}%;" 
                 aria-valuenow="${contentPercentage}" 
                 aria-valuemin="0" 
                 aria-valuemax="100">
              ${contentPercentage}% - ${contentProgress.completed || 0}/${contentProgress.total || 0}
            </div>
          </div>
      `;

      // Add locale-level progress
      if (contentProgress.locales) {
        html += '<div class="locale-progress">';
        Object.entries(contentProgress.locales).forEach(([localeId, localeProgress]) => {
          if (!localeProgress || typeof localeProgress !== 'object') { return; }

          const localePercentage = this.calculateProgress(localeProgress.completed, localeProgress.total);
          
          html += `
            <div class="locale-item mb-2">
              <div class="d-flex justify-content-between align-items-center">
                <small>Locale ${localeId}: ${localeProgress.status || 'unknown'} (${localeProgress.completed || 0}/${localeProgress.total || 0})</small>
                <small>${localePercentage}%</small>
              </div>
              <div class="progress" style="height: 4px; margin-bottom: 8px;">
                <div class="progress-bar bg-info" role="progressbar" 
                     style="width: ${localePercentage}%;" 
                     aria-valuenow="${localePercentage}" 
                     aria-valuemin="0" 
                     aria-valuemax="100">
                </div>
              </div>
              ${localeProgress.currentOperation ? 
                `<small class="current-operation">${localeProgress.currentOperation}</small>` 
                : ''}
            </div>
          `;
        });
        html += '</div>';
      }

      html += '</div>';
    });
    html += '</div></div>';

    return html;
  },

  injectStyles: function() {
    const style = document.createElement('style');
    style.textContent = `
      .time-estimate {
        margin: 10px 0;
        padding: 10px;
        background: #f8f9fa;
        border-radius: 4px;
      }
      .time-info {
        margin: 5px 0;
        color: #666;
      }
      .time-label {
        font-weight: bold;
        margin-right: 10px;
        color: #495057;
      }
      .time-value {
        font-family: monospace;
      }
      .current-operation {
        color: #666;
        font-style: italic;
        font-size: 0.85em;
        display: block;
        margin-top: 2px;
      }
      .locale-item {
        padding: 8px;
        background: #f8f9fa;
        border-radius: 4px;
        margin-bottom: 8px;
      }
      .locale-progress {
        padding-left: 15px;
      }
      .mb-2 {
        margin-bottom: 0.5rem;
      }
      .mb-3 {
        margin-bottom: 1rem;
      }
      .d-flex {
        display: flex;
      }
      .justify-content-between {
        justify-content: space-between;
      }
      .align-items-center {
        align-items: center;
      }
      .bg-info {
        background-color: #17a2b8;
      }
    `;
    document.head.appendChild(style);
  },
};

module.exports = MigrationProgressView; 