'use strict';

const template = require('templates/migrateContentV2History');
const MigrateContentV2List = require('models/migrateContentV2List');
const NotificationEvent = require('NotificationEvent');
const errorHandler = require('utils/errorHandler');
const Spinner = require('Spinner');
const MigrationProgressView = require('./migrationProgressView');

let View, singleton;

View = Backbone.View.extend({
  events: {
    'change #statusFilter': 'onStatusFilterChange',
    'click .view-details': 'onViewDetails',
    'click .retry-job': 'onRetryJob',
    'click .remove-job': 'onRemoveJob',
    'click .cancel-job': 'onCancelJob',
    'click .page-number': 'onPageClick',
    'click .previous-page': 'onPreviousPage',
    'click .next-page': 'onNextPage',
    'click .stat-box': 'onStatBoxClick',
    'click #create-new-migration': 'onCreateNewMigration',
  },

  initialize: function () {
    this.model = new MigrateContentV2List();

    // Set up model change handler
    this.listenTo(this.model, 'sync', this.render);

    // Set initial status from URL if present
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    if (status) {
      this.model.set('status', status);
    }

    // Set initial pagination with offset 0
    this.model.set('pagination', { 
      offset: 0, 
      limit: this.model.defaults.pagination.limit,
    });

    return this;
  },

  remove: function () {
    if (this.progressTracker) {
      this.progressTracker.cleanup();
    }
    clearInterval(this.refreshInterval);
    this.stopListening();
    this.$el.remove();
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  refreshData: function () {
    Spinner.show();
    this.model.fetch({
      success: () => {
        Spinner.hide();
      },
      error: (_model, error) => {
        Spinner.hide();
        errorHandler({
          error: error,
          retry: false,
          fail: () => {
            NotificationEvent.notify('Failed to refresh migration history', 'danger');
          },
        });
      },
    });
  },

  onStatBoxClick: function (e) {
    const $box = $(e.currentTarget);
    if ($box.hasClass('disabled')) {
      return;
    }
    const status = $box.data('status').toLowerCase();
    this.updateStatusFilter(status === 'total' ? '' : status);
  },

  updateStatusFilter: function (status) {
    // Update dropdown
    $('#statusFilter').val(status);

    // Update URL
    const url = new URL(window.location);
    if (status) {
      url.searchParams.set('status', status);
    } else {
      url.searchParams.delete('status');
    }
    window.history.pushState({}, '', url);

    // Update model and refresh data
    this.model.set('status', status);
    this.model.set('pagination', { offset: 0, limit: this.model.defaults.pagination.limit });
    this.refreshData();
  },

  onStatusFilterChange: function (e) {
    const status = e.target.value;
    this.updateStatusFilter(status);
  },

  onViewDetails: function (e) {
    const jobId = $(e.currentTarget).data('id');
    const job = this.model.get('jobs').find((j) => j.id === jobId);

    if (!job) { return; }

    // Create a new MigrateContentV2 model for progress tracking
    if (this.progressTracker) {
      this.progressTracker.cleanup();
    }

    this.progressTracker = new (require('models/migrateContentV2'))({
      migrationId: job.id,
    });

    // Show loading state in modal
    $('.job-details-content').html('<div class="text-center"><i class="fa fa-spinner fa-spin fa-3x"></i><p>Loading job details...</p></div>');
    $('#jobDetailsModal').modal('show');

    // Fetch complete job details
    this.progressTracker.fetchJobDetails()
      .then((jobDetails) => {
        const detailsHtml = `
          <div class="job-details">
            <h4>Migration Information</h4>
            <table class="table table-striped">
              <tr>
                <th style="width: 150px;">ID</th>
                <td><code>${jobDetails.id}</code></td>
              </tr>
              <tr>
                <th>Status</th>
                <td><span class="status-badge status-${jobDetails.state.toLowerCase()}">${jobDetails.state}</span></td>
              </tr>
              <tr>
                <th>User</th>
                <td>${jobDetails.data.user ? jobDetails.data.user.username : 'Unknown'}</td>
              </tr>
              <tr>
                <th>Content IDs</th>
                <td><code>${jobDetails.data.contentIdList ? jobDetails.data.contentIdList.join('</code>, <code>') : ''}</code></td>
              </tr>
              <tr>
                <th>Migration IDs</th>
                <td><code>${jobDetails.data.migrationIds ? jobDetails.data.migrationIds.join('</code>, <code>') : ''}</code></td>
              </tr>
              <tr>
                <th>Started</th>
                <td>${moment(jobDetails.processedOn).format('YYYY-MM-DD HH:mm:ss')}</td>
              </tr>
              <tr>
                <th>Finished</th>
                <td>${jobDetails.finishedOn ? moment(jobDetails.finishedOn).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
              </tr>
              <tr>
                <th>Duration</th>
                <td>${this.calculateDuration(jobDetails.processedOn, jobDetails.finishedOn)}</td>
              </tr>
              <tr>
                <th>Attempts</th>
                <td>${jobDetails.attemptsMade}</td>
              </tr>
            </table>
            
            <div class="accordion" id="jobDetailsAccordion">
              ${jobDetails.failedReason ? `
              <div class="accordion-group error-section">
                <div class="accordion-heading error-header" style="position: sticky; top: 0; background: white; z-index: 100; padding: 15px 0; border-bottom: 1px solid #ddd;">
                  <a class="accordion-toggle cursor-pointer" data-toggle="collapse" data-parent="#jobDetailsAccordion" href="#errorDetails">
                    <div class="clearfix">
                      <h4 class="pull-left" style="margin: 0;">Error Information</h4>
                      <span class="pull-right" style="color: #333; font-size: 16px;">
                        <i class="fa fa-chevron-down toggle-icon"></i>
                      </span>
                    </div>
                  </a>
                </div>
                <div id="errorDetails" class="accordion-body collapse">
                  <div class="accordion-inner" style="padding-top: 15px;">
                    <div class="alert alert-danger">
                      <strong>Reason:</strong> ${jobDetails.failedReason}
                    </div>
                    <pre class="stack-trace bg-light p-3 mt-3" style="margin-top: 15px; background-color: #f5f5f5; padding: 15px;">${jobDetails.stacktrace ? jobDetails.stacktrace.join('\n') : ''}</pre>
                  </div>
                </div>
              </div>
              ` : ''}
              
              <div class="accordion-group progress-section">
                <div class="accordion-heading progress-header" style="position: sticky; top: 0; background: white; z-index: 100; padding: 15px 0; border-bottom: 1px solid #ddd;">
                  <a class="accordion-toggle cursor-pointer" data-toggle="collapse" data-parent="#jobDetailsAccordion" href="#progressDetails">
                    <div class="clearfix">
                      <h4 class="pull-left" style="margin: 0;">Progress Details</h4>
                      <span class="pull-right" style="color: #333; font-size: 16px;">
                        <i class="fa fa-chevron-up toggle-icon"></i>
                      </span>
                    </div>
                  </a>
                </div>
                <div id="progressDetails" class="accordion-body collapse in">
                  <div class="accordion-inner">
                    <div class="content-progress">
                      <!-- Progress will be updated here -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-group results-section">
                <div class="accordion-heading results-header" style="position: sticky; top: 0; background: white; z-index: 100; padding: 15px 0; border-bottom: 1px solid #ddd;">
                  <a class="accordion-toggle cursor-pointer" data-toggle="collapse" data-parent="#jobDetailsAccordion" href="#resultsDetails">
                    <div class="clearfix">
                      <h4 class="pull-left" style="margin: 0;">Migration Results</h4>
                      <span class="pull-right" style="color: #333; font-size: 16px;">
                        <i class="fa fa-chevron-down toggle-icon"></i>
                      </span>
                    </div>
                  </a>
                </div>
                <div id="resultsDetails" class="accordion-body collapse">
                  <div class="accordion-inner">
                    <div class="migration-results">
                      ${this.generateResultsHtml(jobDetails.progress)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;

        $('.job-details-content').html(detailsHtml);

        // Show initial progress if available
        if (jobDetails.progress) {
          this.updateProgressContent(jobDetails.progress);
        }

        // Add collapse event handlers for accordion sections
        $('.accordion-body').on('show.bs.collapse hide.bs.collapse', function (e) {
          const $group = $(this).closest('.accordion-group');
          const $icon = $group.find('.toggle-icon');
          
          if (e.type === 'show' || e.type === 'shown') {
            $icon.removeClass('fa-chevron-down').addClass('fa-chevron-up');
          } else {
            $icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');
          }
        });
      })
      .catch((error) => {
        $('.job-details-content').html(`
          <div class="alert alert-danger">
            <strong>Error loading job details:</strong> ${error.message || 'Unknown error'}
          </div>
        `);
      });

    // Listen for progress updates
    this.listenTo(this.progressTracker, 'progress:update', (progress) => {
      if (!$('#jobDetailsModal').is(':visible')) {
        this.progressTracker.cleanup();
        return;
      }

      // Update progress in modal
      if (progress && progress.details) {
        this.updateProgressContent(progress);
      }
    });

    // Start progress tracking
    this.progressTracker.startProgressTracking();

    // Clean up when modal is hidden
    $('#jobDetailsModal').one('hidden.bs.modal', () => {
      if (this.progressTracker) {
        this.progressTracker.cleanup();
        this.progressTracker = null;
      }
    });
  },

  onRetryJob: function (e) {
    const jobId = $(e.currentTarget).data('id');
    this.model.retryJob(jobId)
      .then(() => {
        NotificationEvent.notify('Job retry initiated successfully', 'success');
      })
      .catch((error) => {
        NotificationEvent.notify('Failed to retry job: ' + error.message, 'danger');
      });
  },

  onRemoveJob: function (e) {
    const jobId = $(e.currentTarget).data('id');
    if (!confirm('Are you sure you want to remove this migration job?')) { return; }

    this.model.removeJob(jobId)
      .then(() => {
        NotificationEvent.notify('Job removed successfully', 'success');
      })
      .catch((error) => {
        NotificationEvent.notify('Failed to remove job: ' + error.message, 'danger');
      });
  },

  onCancelJob: function (e) {
    const jobId = $(e.currentTarget).data('id');
    if (!confirm('Are you sure you want to cancel this migration job?')) { return; }

    this.model.cancelJob(jobId)
      .then(() => {
        NotificationEvent.notify('Job cancelled successfully', 'success');
        this.refreshData();
      })
      .catch((error) => {
        NotificationEvent.notify('Failed to cancel job: ' + error.message, 'danger');
        this.refreshData();
      });
  },

  onPageClick: function (e) {
    e.preventDefault();
    const page = $(e.currentTarget).data('page');
    const pagination = this.model.get('pagination');
    pagination.offset = (page - 1) * pagination.limit;
    this.model.set('pagination', pagination);
    this.refreshData();
  },

  onPreviousPage: function (e) {
    e.preventDefault();
    const pagination = this.model.get('pagination');
    pagination.offset = Math.max(0, pagination.offset - pagination.limit);
    this.model.set('pagination', pagination);
    this.refreshData();
  },

  onNextPage: function (e) {
    e.preventDefault();
    const pagination = this.model.get('pagination');
    const { total } = this.model.get('pagination');
    pagination.offset = Math.min(total - pagination.limit, pagination.offset + pagination.limit);
    this.model.set('pagination', pagination);
    this.refreshData();
  },

  calculateDuration: function (start, end) {
    if (!start) { return '-'; }
    const endTime = end || Date.now();
    const duration = moment.duration(endTime - start);
    return duration.humanize();
  },

  formatDate: function (timestamp) {
    if (!timestamp) { return '-'; }
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
  },

  calculateProgress: function (completed, total) {
    if (!total) { return 0; }
    return Math.round((completed / total) * 100);
  },

  prepareTemplateData: function () {
    const data = this.model.toJSON();
    const pagination = data.pagination || { offset: 0, limit: this.model.defaults.pagination.limit, total: 0 };
    const currentPage = Math.floor(pagination.offset / pagination.limit) + 1;
    const totalPages = Math.ceil(pagination.total / pagination.limit);
    
    // Generate page numbers
    const pages = [];
    const maxPages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    let endPage = Math.min(totalPages, startPage + maxPages - 1);
    
    // Adjust start page if we're near the end
    if (endPage - startPage + 1 < maxPages && startPage > 1) {
      startPage = Math.max(1, endPage - maxPages + 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pages.push({
        number: i,
        active: i === currentPage,
      });
    }

    // Use counts from the API response
    const stats = {
      total: data.counts.total || 0,
      active: data.counts.active || 0,
      completed: data.counts.completed || 0,
      failed: data.counts.failed || 0,
      waiting: data.counts.waiting || 0,
    };

    // Prepare the jobs for display
    if (data.jobs) {
      data.jobs = data.jobs.map((job) => {
        return {
          ...job,
          processedOnFormatted: this.formatDate(job.processedOn),
          finishedOnFormatted: this.formatDate(job.finishedOn),
          duration: this.calculateDuration(job.processedOn, job.finishedOn),
          contentCount: job.data.contentIdList ? job.data.contentIdList.length - 1 : 0,
          migrationCount: job.data.migrationIds ? job.data.migrationIds.length - 1 : 0,
          statusClass: job.state.toLowerCase(),
          user: job.data.user ? job.data.user.username : 'Unknown',
          hasFailed: job.state === 'failed' || !!job.failedReason,
        };
      });

    }

    return {
      ...data,
      pages,
      showPrevious: currentPage > 1,
      showNext: currentPage < totalPages,
      stats,
    };
  },

  render: function () {
    const templateData = this.prepareTemplateData();
    this.$el.html(template(templateData));
    $('#main').append(this.$el);

    MigrationProgressView.injectStyles();

    // Update status filter to match model
    const currentStatus = this.model.get('status') || '';
    $('#statusFilter').val(currentStatus);

    // Highlight active stat box
    this.$('.stat-box').removeClass('active');
    if (currentStatus) {
      this.$(`.stat-box[data-status="${currentStatus}"]`).addClass('active');
    } else {
      this.$('.stat-box[data-status="completed"]').addClass('active');
    }

    this.delegateEvents();

    return this;
  },

  onCreateNewMigration: function (e) {
    const appRouter = require('router');
    appRouter.navigate('migrateContentV2', { trigger: true });
  },

  generateResultsHtml: function(progress) {
    if (!progress || !progress.results || Object.keys(progress.results).length === 0) {
      return '<div class="alert alert-info">No migration results available yet.</div>';
    }

    let html = '<div class="table-responsive"><table class="table table-striped">';
    html += '<thead><tr><th>Locale</th><th>Result</th></tr></thead><tbody>';

    for (const [localeId, result] of Object.entries(progress.results)) {
      html += `
        <tr>
          <td><code>${localeId}</code></td>
          <td>${result}</td>
        </tr>
      `;
    }

    html += '</tbody></table></div>';
    return html;
  },

  updateProgressContent: function(progress) {
    if (!progress || !progress.details) {
      return;
    }
    $('#progressDetails .content-progress').html(MigrationProgressView.generateProgressHtml(progress));
    $('#resultsDetails .migration-results').html(this.generateResultsHtml(progress));
  },
}, {
  showMe: function () {
    if (singleton) {
      singleton.remove();
    }
    singleton = new View();
    singleton.render();
    singleton.refreshData();
    return singleton;
  },
});

module.exports = View; 