'use strict';

const config = require('models/config');
const account = require('models/account');

// Configuration constants
const PAGE_LIMIT = 15;

const MigrateContentV2List = Backbone.Model.extend({
  defaults: {
    jobs: [],
    counts: {},
    status: 'completed',
    pagination: {
      offset: 0,
      limit: PAGE_LIMIT,
      total: 0,
    },
  },

  addClientId: function(url) {
    const querySeparator = url.indexOf('?') === -1 ? '?' : '&';
    return url + querySeparator + 'client_id=' + config.get('authorization').client_id;
  },

  url: function url() {
    let fullUrl = this.addClientId('/v2/migrateContent/history');

    if (this.get('status')) {
      fullUrl += '&status=' + encodeURIComponent(this.get('status'));
    }
    
    // Add pagination params
    const pagination = this.get('pagination');
    fullUrl += '&limit=' + pagination.limit;
    fullUrl += '&offset=' + pagination.offset;
    
    return fullUrl;
  },

  retryJob: function(jobId) {
    return fetch(this.addClientId(`/v2/migrateContent/${jobId}/retry`), {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + account.get('access_token'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to retry job');
      }
      return this.fetch();
    });
  },

  removeJob: function(jobId) {
    return fetch(this.addClientId(`/v2/migrateContent/${jobId}`), {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + account.get('access_token'),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to remove job');
      }
      return this.fetch();
    });
  },

  cancelJob: function(jobId) {
    return fetch(this.addClientId(`/v2/migrateContent/${jobId}/cancel`), {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + account.get('access_token'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to cancel job');
      }
      return this.fetch();
    });
  },
});

module.exports = MigrateContentV2List; 