'use strict';

const
  errorHandler = require('utils/errorHandler'),
  JsonPatchUtils = require('utils/JsonPatchUtils'),
  TagsUtils = require('utils/TagsUtils'),
  Utils = require('utils/utils'),
  validateContentSpecificRules = require('utils/validateContentSpecificRules'),
  notificationEvent = require('NotificationEvent'),
  FilterOnEvent = require('FilterOnEvent'),
  TreeItemTools = require('utils/TreeItemTools');

let Release = Backbone.Model.extend({
  collection: {
    url: '/v1/releases',
  },

  url: function url() {
    let url = '/v1/releases/' + this.id;
    if (this.get('content_id') && this.get('locale')) {
      url = '/v1/contents/' + this.get('content_id') + '/locales/' + this.get('locale') + '/releases';
      if (!_.isUndefined(this.id)) {
        url += '/' + this.id;
        if (this.get('infoOnly')) {
          url += '/info';
        }
      }
    }
    return url + '?metadata=true&disabledNodes=true&schema=true';
  },

  formatDraftForPatch: function formatDraftForPatch(input) {
    const jsonRelease = this.toJSON();
    if (input) {
      if (input.isPublish) {
        jsonRelease.status = 'live';
      }
      if (input.isRollback) {
        jsonRelease.isRollback = true;
      }
      if (_.has(input, 'title')) {
        jsonRelease.title = input.title;
      }
      if (_.has(input, 'last_update_user')) {
        jsonRelease.metadata.last_update_user = input.last_update_user;
      }
    }
    delete jsonRelease.value;
    delete jsonRelease.schema;
    delete jsonRelease.jsonpatch_published_ops;
    delete jsonRelease.value_validation_errors;
    delete jsonRelease.pageCount;
    delete jsonRelease.live_release_id;
    delete jsonRelease.migration_id;
    return jsonRelease;
  },

  resetJsonPatch: function resetJsonPatch(tagsListModificationsSaved) {
    if (tagsListModificationsSaved || !this.get('tagsListModified')) { // TODO test function without if conditions (they might be useless)
      if (tagsListModificationsSaved) {
        this.set('tagsListModified', null);
      }
      this.set('self_jsonpatch_ops', []);
    }
  },

  getDcxPageIndexPath: (path) => path.match(/([a-zA-Z]+(p|P)ages?)\.pages\.(\d+)/g) ?? null,

  getDcxPagePageId: function (path) {
    return this.getValue(`${this.getDcxPageIndexPath(path)}.pageId`) ?? null;
  },

  getPageIndex: (path) => path.match(/(?<=pages\.)(\d+)/g) ?? null,

  getPagePageId: function (path) {
    return this.getValue(`pages.${this.getPageIndex(path)}.pageId`) ?? null;
  },

  pushOperation: function pushOperation(isMoveOp, op, path, value) {
    let pathOperation = _.clone(path);
    let valueOperation = _.cloneDeep(value);
    const contentId = this.get('content_id') ?? '';
    const tagsMetadata = this.get('tagsMetadata');
    let pageId = null;
    let pageUrlPattern = null;

    const jsonPath = Utils.formatToJsonPath(path);
    if (TagsUtils.contentHasTagsEnabled(contentId)) {
      pageId = this.getPagePageId(jsonPath);
      if (TagsUtils.jsonPathStartsWithTaggedRootPath(contentId, jsonPath, tagsMetadata?.prefix)) {
        pathOperation = TagsUtils.updateTagsPathIndex(this, isMoveOp, op, pathOperation);
      }
      if(op === 'move' && TagsUtils.jsonPathStartsWithTaggedRootPath(contentId, valueOperation, tagsMetadata?.prefix)) {
        valueOperation = TagsUtils.updateTagsPathIndex(this, isMoveOp, op, valueOperation);
      }
    }
    if (contentId.indexOf('dcx') !== -1) {
      pageId = this.getDcxPagePageId(jsonPath);
    }


    // Get pageUrlPattern by traversing up the path
    let currentPath = jsonPath;
    while (currentPath) {
      const section = _.get(this.get('value'), currentPath);
      
      if (_.isObject(section) && !_.isArray(section)) {
        // Use TreeItemTools.iterate to find pageUrlPatterns
        TreeItemTools.iterate(section, '', (property, value, stack) => {
          if (property === 'pageUrlPatterns' && value) {
            pageUrlPattern = value;
            return false; // Stop iteration once we find the first pattern
          }
        });
        
        if (pageUrlPattern) break;
      }
      
      currentPath = Utils.getParentPath(currentPath);
    }

    if (op === 'remove') {
      this.trigger('delete', { type: 'delete', path });
    }

    const newOperation = JsonPatchUtils.buildOperation(isMoveOp, op, pathOperation, valueOperation);

    if (pageId) {
      newOperation.pageId = pageId;
    } else if (pageUrlPattern) {
      // If we don't have a pageId but we have a pattern, use that
      newOperation.pageUrlPattern = pageUrlPattern;
    }

    const newJsonPatchList = this.get('self_jsonpatch_ops');
    newJsonPatchList.push(newOperation);
    this.set('self_jsonpatch_ops', newJsonPatchList);
  },

  pathExists: function pathExists(path) {
    return _.has(this.get('value'), path);
  },

  getSchema: function getSchema(path) {
    const value = this.getValue(path);
    const oneOfType = _.get(value, '@metadata.type');
    if (oneOfType) {
      return this.getSchemaDefinitionFor('', oneOfType);
    }
    return this.getSchemaDefinitionFor(path, oneOfType);

  },

  getValue: function getValue(path) {
    return _.get(this.get('value'), path);
  },

  setValue: function setValue(path, newValue) {
    const clonedNewValue = _.cloneDeep(newValue);
    if (!_.isObject(newValue)) {
      const op = (this.pathExists(path)) ? 'replace' : 'add';
      this.pushOperation(false, op, path, clonedNewValue);
    }
    _.set(this.get('value'), path, clonedNewValue);
    this.trigger('change');
  },

  addDisabledMetadataToReleaseValue: function addDisabledMetadataToReleaseValue(model, jsonPath, $element) {
    if (model && jsonPath) {
      const modelValue = this.getValue(jsonPath);
      if (Utils.isNotEmptyObject(modelValue)) {
        const disabled = { disabled: true };
        const metadata = { '@metadata': disabled };
        if (_.isArray(modelValue) && modelValue.length) {
          TreeItemTools.toggleArrayNodes($element, false);

          const modelChildrenModels = model._nodes.models;
          for (let i = 0; i < modelValue.length; i++) {
            const elementModel = modelChildrenModels[i];
            elementModel.set('disabled', true);
            const elementValue = modelValue[i];
            const elementJsonPath = jsonPath + '.' + i;
            let newElementValue = _.cloneDeep(elementValue);
            newElementValue = _.merge({}, newElementValue, metadata);
            if (_.has(elementValue, '@metadata')) {
              if (!_.has(elementValue, '@metadata.disabled')) {
                this.pushOperation(false, 'add', elementJsonPath + '.@metadata.disabled', true);
              }
            } else {
              this.pushOperation(false, 'add', elementJsonPath + '.@metadata', disabled);
            }
            this.setValue(elementJsonPath, newElementValue);
          }
        } else {
          let newModelValue = _.cloneDeep(modelValue);
          newModelValue = _.merge({}, newModelValue, metadata);
          if (_.has(modelValue, '@metadata')) {
            if (!_.has(modelValue, '@metadata.disabled')) {
              this.pushOperation(false, 'add', jsonPath + '.@metadata.disabled', true);
            }
          } else {
            this.pushOperation(false, 'add', jsonPath + '.@metadata', disabled);
          }
          this.setValue(jsonPath, newModelValue);
        }
      }
    }
  },

  removeDisabledMetadataFromReleaseValue: function removeDisabledMetadataFromReleaseValue(model, jsonPath, $element) {
    if (model && jsonPath) {
      const modelValue = this.getValue(jsonPath);
      if (Utils.isNotEmptyObject(modelValue)) {
        if (_.isArray(modelValue) && modelValue.length) {
          TreeItemTools.toggleArrayNodes($element, true);

          const modelChildrenModels = model._nodes.models;
          for (let i = 0; i < modelValue.length; i++) {
            const elementModel = modelChildrenModels[i];
            elementModel.set('disabled', false);

            const elementValue = modelValue[i];
            const elementJsonPath = jsonPath + '.' + i;
            const newElementValue = _.cloneDeep(elementValue);
            if (newElementValue['@metadata'] && newElementValue['@metadata'].disabled) {
              delete newElementValue['@metadata'].disabled;
              if (!Utils.isNotEmptyObject(newElementValue['@metadata'])) {
                delete newElementValue['@metadata'];
                this.pushOperation(false, 'remove', elementJsonPath + '.@metadata');
              } else {
                this.pushOperation(false, 'remove', elementJsonPath + '.@metadata.disabled');
              }
            }
            this.setValue(elementJsonPath, newElementValue);
          }
        } else {
          const newModelValue = _.cloneDeep(modelValue);
          if (newModelValue['@metadata'] && newModelValue['@metadata'].disabled) {
            delete newModelValue['@metadata'].disabled;
            if (!Utils.isNotEmptyObject(newModelValue['@metadata'])) {
              delete newModelValue['@metadata'];
              this.pushOperation(false, 'remove', jsonPath + '.@metadata');
            } else {
              this.pushOperation(false, 'remove', jsonPath + '.@metadata.disabled');
            }
          }
          this.setValue(jsonPath, newModelValue);
        }
      }
    }
  },

  removeItem: function removeItem(path) {
    const refParent = path.substring(0, path.lastIndexOf('.'));
    const index = path.substring(path.lastIndexOf('.') + 1);
    const releaseCollection = _.get(this.get('value'), refParent);
    _.pullAt(releaseCollection, index);
    this.trigger('change');
  },

  concurrencyCheck: function concurrencyCheck(noRetry) {
    const self = this;
    const concurrencyCheckPromise = $.Deferred();
    const knownReleaseState = this;
    const knownStatus = knownReleaseState.get('status');
    const actualReleaseState = new Release({
      id: knownReleaseState.get('id'),
      content_id: knownReleaseState.get('content_id'),
      locale: knownReleaseState.get('locale'),
    });
    actualReleaseState.fetch({
      success: function (actualReleaseState) {
        const actualStatus = actualReleaseState.get('status');
        if (actualStatus !== knownStatus) {
          const actualLastUpdateDate = actualReleaseState.get('metadata').last_update_date;
          const actualLastUpdateUser = actualReleaseState.get('metadata').last_update_user;
          const formattedActualLastUpdateDate = Utils.formatDate(actualLastUpdateDate);
          FilterOnEvent.trigger('iniErrors');
          const message = 'You cannot modify the release you are currently working on because <b>its status has changed:</b>'
            + '<br/><ul>'
            + '<li>by: ' + actualLastUpdateUser + '</li>'
            + '<li>on: ' + formattedActualLastUpdateDate + '</li>'
            + '</ul>'
            + '<br/>Please refresh this page to view the new state of the release and apply your modifications again.';
          notificationEvent.notify(message, 'danger');
          concurrencyCheckPromise.reject();
        } else {
          concurrencyCheckPromise.resolve();
        }
      },
      error: function (error) {
        errorHandler({
          error: error,
          retry: !noRetry,
          success: function () {
            if (!noRetry) {
              self.concurrencyCheck(true);
            }
          },
          fail: function (jsonError) {
            if (jsonError && jsonError.statusCode === 404) {
              FilterOnEvent.trigger('iniErrors');
              const message = 'You cannot modify the release you are currently working on because <b>it was deleted!</b>'
                + '<br/><br/>Please go back to the releases page and apply your modifications to a new draft.';
              notificationEvent.notify(message, 'danger');
            }
            concurrencyCheckPromise.reject();
          },
        });
      },
    });
    return concurrencyCheckPromise;
  },

  self_jsonpatch_opsCheck: function self_jsonpatch_opsCheck() {
    const self_jsonpatch_opsCheckPromise = $.Deferred();
    const knownReleaseState = this;
    const knownSelf_jsonpatch_ops = knownReleaseState.get('self_jsonpatch_ops');
    if (knownSelf_jsonpatch_ops && knownSelf_jsonpatch_ops.length !== 0) {
      const errors = JsonPatchUtils.validateJsonpatch(knownSelf_jsonpatch_ops);
      if (errors && errors.length > 0) {
        FilterOnEvent.trigger('iniErrors');
        let message = 'You cannot save your release because your modification list has errors, please send them to an admin:<br/><br/>';
        for (let i = 0; i < errors.length; i++) {
          const error = errors[i];
          message += '- ' + error + '<br />';
        }
        message += '<br/>Please refresh this page to view the current state of the release and apply your modifications again.';
        notificationEvent.notify(message, 'danger');
        self_jsonpatch_opsCheckPromise.reject();
      } else {
        self_jsonpatch_opsCheckPromise.resolve();
      }
    } else {
      self_jsonpatch_opsCheckPromise.resolve();
    }
    return self_jsonpatch_opsCheckPromise;
  },

  specificContentValueCheck: function specificContentValueCheck() {
    const specificContentValueCheckPromise = $.Deferred();
    const knownReleaseState = this;
    const knownValue = knownReleaseState.get('value');
    const tagsMetadata = knownReleaseState.get('tagsMetadata') || {};
    const contentSpecificErrors = validateContentSpecificRules({
      contentId: this.get('content_id'),
      tagsMetadata,
      value: knownValue,
    });
    if (Utils.isNotEmptyArray(contentSpecificErrors)) {
      specificContentValueCheckPromise.reject(contentSpecificErrors);
    } else {
      specificContentValueCheckPromise.resolve();
    }
    return specificContentValueCheckPromise;
  },

  /**
   * Returns the schema definition of a field based on its reference
   * @param ref Reference of the field (ex: object1.object2.field)
   */
  getSchemaDefinitionFor: function getSchemaDefinitionFor(ref, definition) {
    const schema = this.get('schema');
    if (!schema || _.isEmpty(schema)) {
      return null;
    }

    let subSchema = schema;
    if (definition) {
      const definitionName = definition.replace(/#\/definitions\//, '');
      if (definitionName && schema.definitions[definitionName]) {
        subSchema = schema.definitions[definitionName];
      } else {
        return null;
      }
    }

    const tabRef = (ref) ? ref.split('.') : [];

    for (let i = 0; i < tabRef.length; i++) {

      const currentRef = tabRef[i];
      if (!subSchema.properties || !subSchema.properties[currentRef]) {
        return null;
      }
      subSchema = subSchema.properties[currentRef];

      subSchema = this.getSchemaFromRef(subSchema, schema);

      // jump above the position in the array in the ref until we don't get an array
      while (subSchema.type === 'array' && i !== tabRef.length - 1) {
        subSchema = subSchema.items;
        i++;
        subSchema = this.getSchemaFromOneOf(subSchema, definition);
      }

      subSchema = this.getSchemaFromRef(subSchema, schema);

      if (i === tabRef.length - 1) {
        break;
      }
    }

    const retour = _.clone(subSchema);
    retour.definitions = _.clone(schema.definitions);
    return retour;
  },

  getSchemaFromOneOf: function getSchemaFromOneOf(subSchema, definition) {
    let clonedSubSchema = _.clone(subSchema);
    if (clonedSubSchema.oneOf && definition) {
      clonedSubSchema = clonedSubSchema.oneOf;
      return this.getSchemaFromDefinition(clonedSubSchema, definition);
    }
    return subSchema;
  },

  getSchemaFromDefinition: function getSchemaFromDefinition(subSchema, definition) {
    const clonedSubSchema = _.clone(subSchema);
    for (let i = 0; i < clonedSubSchema.length; i++) {
      if (clonedSubSchema[i].$ref && clonedSubSchema[i].$ref == definition) {
        return clonedSubSchema[i];
      }
    }
    return subSchema;
  },

  getSchemaFromRef: function getSchemaFromRef(subSchema, schema) {
    const clonedSubSchema = _.clone(subSchema);
    if (clonedSubSchema.$ref) {
      const definitionName = clonedSubSchema.$ref.replace(/#\/definitions\//, '');
      delete clonedSubSchema.$ref;
      const definitionSubSchema = schema.definitions[definitionName] ? schema.definitions[definitionName] : {};
      return _.merge({}, definitionSubSchema, clonedSubSchema);
    }
    return subSchema;
  },
});

module.exports = Release;
