'use strict';

const config = require('models/config');
const account = require('models/account');

const MigrateContentV2 = Backbone.Model.extend({
  defaults: {
    migrationList: [
      {
        id: '',
        contentId: '',
        locales: ['all'],
        migrations: [],
      },
    ],
    migrationId: '',
  },

  collection: {
    url: '/v2/migrateContent',
  },

  url: function url() {
    var url = '/v2/migrateContent?htmlResponse=true';
    if (this.get('migrationId')) {
      url += '&migrationId=' + encodeURIComponent(this.get('migrationId'));
    }
    return url;
  },

  startProgressTracking: function() {
    const url = '/v2/migrateContent/progress';
    const querySeparator = url.indexOf('?') === -1 ? '?' : '&';
    let fullUrl = url + querySeparator + 'client_id=' + config.get('authorization').client_id;
    if (this.get('migrationId')) {
      fullUrl += '&migrationId=' + encodeURIComponent(this.get('migrationId'));
    }

    fetch(fullUrl, {
      headers: {
        Accept: 'text/event-stream',
        Authorization: 'Bearer ' + account.get('access_token'),
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const reader = response.body.getReader();
      let buffer = '';

      const readChunk = () => {
        reader.read()
          .then(({ value, done }) => {
            if (done) {
              console.log('Stream finished');
              return;
            }

            const chunk = new TextDecoder().decode(value);
            buffer += chunk;

            const messages = buffer.split('\n\n');
            buffer = messages.pop() || '';

            messages.forEach((message) => {
              if (message.trim() && message.startsWith('data: ')) {
                try {
                  const data = message.replace(/^data:\s*/, '');
                  const progress = JSON.parse(data);
                  
                  if (progress && typeof progress === 'object') {
                    this.set('progress', progress);
                    this.trigger('progress:update', progress);
                  } else {
                    console.warn('Received invalid progress data structure:', progress);
                  }
                } catch (err) {
                  console.error('Error parsing progress data:', err);
                  this.trigger('progress:error', err);
                }
              }
            });

            readChunk();
          })
          .catch((error) => {
            console.error('Error reading chunk:', error);
            this.trigger('progress:error', error);
          });
      };

      readChunk();
    })
    .catch((error) => {
      console.error('Progress tracking error:', error);
      this.trigger('progress:error', error);
    });
  },

  cleanup: function() {
    if (this.abortController) {
      this.abortController.abort();
    }
  },

  fetchJobDetails: function() {
    return new Promise((resolve, reject) => {
      if (!this.get('migrationId')) {
        return reject(new Error('No migration ID provided'));
      }

      const url = `/v2/migrateContent/job/${encodeURIComponent(this.get('migrationId'))}?client_id=${config.get('authorization').client_id}`;
      
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + account.get('access_token'),
        },
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((data) => {
              throw new Error(data?.message || 'Failed to fetch job details');
            });
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
});

module.exports = MigrateContentV2;
